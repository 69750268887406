import Vue from "vue";
import VueRouter from "vue-router";
import Store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/",
    component: () =>
      import(/* webpackChunkName: "baseLayout" */ "@/layouts/BaseLayout.vue"),
    meta: {
      requiresAuth: false
    },
    children: [
      {
        path: "/",
        name: "Home",
        component: () =>
          import(/* webpackChunkName: "error" */ "@/views/HomeView.vue"),
        meta: {
          requiresAuth: false,
          title: "Cuenta Pública - Municipalidad de Quilpué"
        }
      },
      {
        path: "/cuentas-anteriores",
        name: "CuentasAnteriores",
        component: () =>
          import(
            /* webpackChunkName: "error" */ "@/views/Cuentas/CuentaAnteriorView.vue"
          ),
        meta: {
          requiresAuth: false,
          title: "Cuentas Anteriores - Municipalidad de Quilpué"
        }
      },
      {
        path: "/cuenta-publica/:nombre",
        name: "CuentaPublica",
        component: () =>
          import(
            /* webpackChunkName: "error" */ "@/views/Cuentas/CuentaPerfilView.vue"
          ),
        meta: {
          requiresAuth: false,
          title: "Cuenta Pública - Municipalidad de Quilpué"
        }
      },
      {
        path: "/cuenta-publica/:nombre/:id",
        name: "Direccion",
        component: () =>
          import(
            /* webpackChunkName: "error" */ "@/views/Cuentas/DireccionPerfilView.vue"
          ),
        meta: {
          requiresAuth: false,
          title: "Cuenta Pública - Municipalidad de Quilpué"
        }
      }
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
